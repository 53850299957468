import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Button } from '../../ui-components/Button';
import { MFLogo } from '../../ui-components/MFLogo';

import { handleError } from '../../actions/errorHandler.actions';

import styles from './ErrorHadler.module.scss';

class ErrorHandler extends Component {
  state = { hasError: false };

  static propTypes = {
    handleError: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    this.props.handleError({ error: String(error) });
    localStorage.clear();
  }

  onHandleError = () => {
    this.props.history.push('/');
    this.setState({ hasError: false });
  };

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return (
        <div className={styles.root}>
          <div>
            <MFLogo className={styles.logo} />
            <h2 className={styles.title}>Произошла ошибка</h2>
            <div className={styles.text}>Приносим извинения за доставленные неудобства, мы уже работаем над ошибкой. Повторите попытку позже.</div>
            <Button size="m" fontWeight="bold" onClick={this.onHandleError}>
              Перейти на главную
            </Button>
          </div>
        </div>
      );
    }
    return <>{this.props.children}</>;
  }
}

const mapDispatchToProps = (dispatch, actions = {}) => {
  actions.handleError = handleError;
  return bindActionCreators(actions, dispatch);
};

const ErrorHandlerWithRouter = withRouter(connect(null, mapDispatchToProps)(ErrorHandler));

export { ErrorHandlerWithRouter, ErrorHandler };
