import { merge } from 'lodash/object';

export const FETCH_DEBTOR_LIST = 'FETCH_DEBTOR_LIST';
export const FETCH_FACTOR_LIST = 'FETCH_FACTOR_LIST';
export const FETCH_SUPPLIER_LIST = 'FETCH_SUPPLIER_LIST';

const defaultSortParams = { sort: { by: 'name', direction: 'ASC' } };

export const fetchFilterCompanies = (companyType = 'DEBTOR', params = {}) => ({
  type: `FETCH_${companyType}_LIST`,
  payload: {
    request: {
      method: 'GET',
      url: `filters/companies/${companyType}`,
      params: merge(params, defaultSortParams),
    }
  }
});
