import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import PrivateRoute from '../PrivateRoute';
import AccessControl from '../AccessControl';

function AccessControlRoute({ component: Component, allowedPermissions, ...props }) {
  const RouteComponent = useCallback(routeProps => (
    <AccessControl
      allowedPermissions={allowedPermissions}
      renderNoAccess={() => <Redirect to={{ pathname: '/error/403', state: { from: routeProps.location } }} />}>
      <Component {...routeProps} />
    </AccessControl>
  ), [Component, allowedPermissions]);

  return <PrivateRoute {...props} component={RouteComponent} />;
}

AccessControlRoute.propTypes = {
  component: PropTypes.func,
  allowedPermissions: PropTypes.string,
};

AccessControlRoute.defaultProps = {
  component: f => f,
  allowedPermissions: '',
};

export default AccessControlRoute;
