export const FETCH_BADGES = 'FETCH_BADGES';

export const fetchBadges = () => ({
  type: FETCH_BADGES,
  payload: {
    request: {
      method: 'GET',
      url: 'badges'
    }
  }
});
