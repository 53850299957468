import {
  NEW_REGISTRY,
  UPDATE_NEW_REGISTRY,
  CANCEL_NEW_REGISTRY,
  CREATE_NEW_REGISTRY
} from './RegistryNew.actions';

const initialState = {
  fromLocation: {},
  contract: [],
  supply: [],
  isFetching: false,
  isError: false,
  error: {
    status: null,
    records: []
  },
  data: {},
  activeTabId: 1,
  tabs: [
    {
      id: 1,
      active: true,
      disabled: false,
      prev: false,
      next: false
    },
    {
      id: 2,
      active: false,
      disabled: true,
      prev: true,
      next: false
    }
  ]
};

export default function (state = initialState, { type, payload, error }) {
  switch (type) {
    case NEW_REGISTRY:
      return {
        ...initialState,
        ...payload
      };

    case UPDATE_NEW_REGISTRY:
      return {
        ...state,
        ...payload,
        error: initialState.error
      };

    case CANCEL_NEW_REGISTRY:
      return { ...initialState };

    case CREATE_NEW_REGISTRY:
      return {
        ...state,
        isFetching: true,
        isError: false,
      };

    case `${CREATE_NEW_REGISTRY}_SUCCESS`:
      return { ...initialState };

    case `${CREATE_NEW_REGISTRY}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...state.error, status: error.response.status, records: error.response.data.supplyErrorList.records }
      };

    default:
      return state;
  }
}
