export const FETCH_SUPPLY = 'FETCH_SUPPLY';
export const FETCH_SUPPLY_BY_CONTRACT_ID = 'FETCH_SUPPLY_BY_CONTRACT_ID';
export const UPDATE_SUPPLY_FILTERS = 'UPDATE_SUPPLY_FILTERS';
export const CLEAR_SUPPLY_FILTERS = 'CLEAR_SUPPLY_FILTERS';
export const LOAD_MORE_SUPPLY = 'LOAD_MORE_SUPPLY';
export const SET_SUPPLY_SORTING = 'SET_SUPPLY_SORTING';

export const fetchSupply = (params = {}) => ({
  type: FETCH_SUPPLY,
  payload: {
    request: {
      method: 'GET',
      url: 'supply',
      params
    }
  }
});

export const updateSupplyFilters = payload => dispatch => dispatch({
  type: UPDATE_SUPPLY_FILTERS,
  payload
});

export const clearSupplyFilters = payload => dispatch => dispatch({
  type: CLEAR_SUPPLY_FILTERS,
  payload
});

export const loadMoreSupply = (params = {}) => ({
  type: LOAD_MORE_SUPPLY,
  payload: {
    request: {
      method: 'GET',
      url: 'supply',
      params
    }
  }
});

export const setSupplySorting = payload => dispatch => dispatch({
  type: SET_SUPPLY_SORTING,
  payload,
});
