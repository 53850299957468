import {
  NEW_FACTORING_CONTRACT,
  UPDATE_NEW_FACTORING_CONTRACT,
  CANCEL_NEW_FACTORING_CONTRACT,
  CREATE_NEW_FACTORING_CONTRACT,
  NEW_FACTORING_CONTRACT_FETCH_SUPPLIER_LIST,
  NEW_FACTORING_CONTRACT_LOAD_MORE_SUPPLIERS,
  NEW_FACTORING_CONTRACT_FETCH_SUPPLY_CONTRACTS,
  NEW_FACTORING_CONTRACT_LOAD_MORE_SUPPLY_CONTRACTS
} from './FactoringContractNew.actions';

export const initialState = {
  fromLocation: {},
  isFetching: false,
  isError: false,
  activeTabId: 1,
  error: null,
  supplierList: {
    isFetching: false,
    isError: false,
    error: null,
    data: { records: [] }
  },
  supplyContractsList: {
    isFetching: false,
    isError: false,
    error: null,
    data: { records: [] }
  },
  selectedSupplyContracts: [],
  selectedSupplier: null,
  tabs: [
    {
      id: 1,
      active: true,
      disabled: false,
      prev: false,
      next: false
    },
    {
      id: 2,
      active: false,
      disabled: true,
      prev: true,
      next: false
    },
    {
      id: 3,
      active: false,
      disabled: true,
      prev: true,
      next: false
    }
  ]
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case NEW_FACTORING_CONTRACT:
      return {
        ...initialState,
        ...payload
      };

    case UPDATE_NEW_FACTORING_CONTRACT:
      return {
        ...state,
        ...payload
      };

    case CANCEL_NEW_FACTORING_CONTRACT:
      return { ...initialState };

    case CREATE_NEW_FACTORING_CONTRACT:
      return {
        ...state,
        isFetching: true
      };

    case `${CREATE_NEW_FACTORING_CONTRACT}_SUCCESS`:
      return {
        ...initialState,
        isFetching: false,
        isError: false,
        error: null
      };

    case `${CREATE_NEW_FACTORING_CONTRACT}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case NEW_FACTORING_CONTRACT_FETCH_SUPPLIER_LIST:
      return {
        ...state,
        supplierList: {
          ...initialState.supplierList,
          isFetching: true,
          isError: false,
          error: null
        },
        isFetching: true,
        isError: false,
        error: null
      };

    case `${NEW_FACTORING_CONTRACT_FETCH_SUPPLIER_LIST}_SUCCESS`:
      return {
        ...state,
        supplierList: {
          isFetching: false,
          isError: false,
          error: null,
          data: { ...payload.data },
        },
        isFetching: false,
        isError: false,
        error: null
      };

    case `${NEW_FACTORING_CONTRACT_FETCH_SUPPLIER_LIST}_FAIL`:
      return {
        ...state,
        supplierList: {
          ...state.supplierList,
          isFetching: false,
          isError: true,
          error: { ...payload }
        },
        isFetching: false,
        isError: true
      };

    case NEW_FACTORING_CONTRACT_LOAD_MORE_SUPPLIERS:
      return {
        ...state,
        supplierList: {
          ...state.supplierList,
          isFetching: true,
          isError: false,
          error: null
        },
        isFetching: true,
        isError: false,
        error: null
      };

    case `${NEW_FACTORING_CONTRACT_LOAD_MORE_SUPPLIERS}_SUCCESS`:
      return {
        ...state,
        supplierList: {
          ...state.supplierList,
          isFetching: false,
          isError: false,
          error: null,
          data: {
            ...payload.data,
            records: [...state.supplierList.data.records, ...payload.data.records],
          },
        },
        isFetching: false,
        isError: false,
        error: null
      };

    case `${NEW_FACTORING_CONTRACT_LOAD_MORE_SUPPLIERS}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        supplierList: {
          ...state.supplierList,
          isFetching: false,
          isError: true,
          error: { ...payload }
        },
        error: { ...payload }
      };

    case NEW_FACTORING_CONTRACT_FETCH_SUPPLY_CONTRACTS:
      return {
        ...state,
        supplyContractsList: {
          ...initialState.supplyContractsList,
          isFetching: true,
          isError: false,
          error: null
        },
        isFetching: true,
        isError: false,
        error: null
      };

    case `${NEW_FACTORING_CONTRACT_FETCH_SUPPLY_CONTRACTS}_SUCCESS`:
      return {
        ...state,
        supplyContractsList: {
          isFetching: false,
          isError: false,
          error: null,
          data: { ...payload.data }
        },
        isFetching: false,
        isError: false,
        error: null
      };

    case `${NEW_FACTORING_CONTRACT_FETCH_SUPPLY_CONTRACTS}_FAIL`:
      return {
        ...state,
        supplyContractsList: {
          ...state.supplyContractsList,
          isFetching: false,
          isError: true,
          error: { ...payload }
        },
        isFetching: false,
        isError: true
      };

    case NEW_FACTORING_CONTRACT_LOAD_MORE_SUPPLY_CONTRACTS:
      return {
        ...state,
        supplyContractsList: {
          ...state.supplyContractsList,
          isFetching: true,
          isError: false,
          error: null
        },
        isFetching: true,
        isError: false,
        error: null
      };

    case `${NEW_FACTORING_CONTRACT_LOAD_MORE_SUPPLY_CONTRACTS}_SUCCESS`:
      return {
        ...state,
        supplyContractsList: {
          ...state.supplyContractsList,
          ...payload.data,
          isFetching: false,
          isError: false,
          error: null,
          data: {
            ...state.supplyContractsList.data,
            ...payload.data,
            records: [...state.supplyContractsList.data.records, ...payload.data.records]
          }
        },
        isFetching: false,
        isError: false,
        error: null
      };

    case `${NEW_FACTORING_CONTRACT_LOAD_MORE_SUPPLY_CONTRACTS}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        supplyContractsList: {
          ...state.supplyContractsList,
          isFetching: false,
          isError: true,
          error: { ...payload }
        },
        error: { ...payload }
      };

    default:
      return state;
  }
}
