export const ROLES = Object.defineProperties({}, {
  DEBTOR: {
    value: 'DEBTOR',
    writable: false
  },
  FACTOR: {
    value: 'FACTOR',
    writable: false
  },
  SUPPLIER: {
    value: 'SUPPLIER',
    writable: false
  },
  ADMIN: {
    value: 'ADMIN',
    writable: false
  }
});
