import { CREATE_NOTIFICATION, REMOVE_NOTIFICATION } from '../actions/notifications.actions';
import { LOGOUT } from '../actions/auth.actions';

const initialState = [];

export default function notificationsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case CREATE_NOTIFICATION:
      return [{ ...payload }, ...state];
    case REMOVE_NOTIFICATION:
      return [...state.filter(({ id }) => id !== payload.id)];
    case `${LOGOUT}_SUCCESS`:
      return initialState;
    default:
      return state;
  }
}
