import { flow, tryCatch } from '../utils/fp';

const decodeBase64 = flow(window.atob, escape, decodeURIComponent);
const decodeJson = tryCatch(flow(decodeBase64, JSON.parse), () => null);

const interceptor = [
  response => response,
  error => {
    const { response: { data = {} } } = error;

    if (data.hasOwnProperty('userMessage')) {
      const json = decodeJson(data.userMessage);
      if (json !== null) error.response.data = json;
    }

    return Promise.reject(error);
  },
];

export default interceptor;
