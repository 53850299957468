import { onLogout } from './auth.actions';

export const FETCH_SUPPORT_NOTIFICATIONS = 'FETCH_SUPPORT_NOTIFICATIONS';

export const fetchSupportNotifications = () => ({
  type: FETCH_SUPPORT_NOTIFICATIONS,
  payload: {
    request: {
      method: 'GET',
      url: 'support-notifications',
      retry: {
        retries: 2,
        retryDelay: i => i * 1000,
        throwError: false,
        onErrorCallback: dispatch => dispatch(onLogout()),
      },
    },
  }
});
