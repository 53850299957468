export const FETCH_REGISTRY_BY_ID = 'FETCH_REGISTRY_BY_ID';
export const FETCH_REGISTRY_TIMELINE_BY_ID = 'FETCH_REGISTRY_TIMELINE_BY_ID';
export const SIGN_REGISTRY = 'SIGN_REGISTRY';
export const DELETE_REGISTRY = 'DELETE_REGISTRY';
export const COMMENT_REGISTRY = 'COMMENT_REGISTRY';
export const FETCH_REGISTRY_COMMENTS = 'FETCH_REGISTRY_COMMENTS';
export const FETCH_REGISTRY_SUPPLY = 'FETCH_REGISTRY_SUPPLY';
export const FETCH_REGISTRY_HISTORY = 'FETCH_REGISTRY_HISTORY';


export const fetchRegistryById = documentId => ({
  type: FETCH_REGISTRY_BY_ID,
  payload: {
    request: {
      method: 'GET',
      url: `registries/${documentId}`
    }
  }
});

export const fetchRegistrySupply = documentId => ({
  type: FETCH_REGISTRY_SUPPLY,
  payload: {
    request: {
      method: 'GET',
      url: `registries/${documentId}/supply`
    }
  }
});

export const signRegistry = (data, documentId) => ({
  type: SIGN_REGISTRY,
  payload: {
    request: {
      method: 'PATCH',
      url: `registries/${documentId}`,
      data
    }
  }
});

export const deleteRegistry = documentId => ({
  type: DELETE_REGISTRY,
  payload: {
    request: {
      method: 'DELETE',
      url: `registries/${documentId}`,
    }
  }
});

export const fetchRegistryTimelineById = documentId => ({
  type: FETCH_REGISTRY_TIMELINE_BY_ID,
  payload: {
    request: {
      method: 'GET',
      url: `registries/${documentId}/timeline`
    }
  }
});

export const fetchRegistryComments = documentId => ({
  type: FETCH_REGISTRY_COMMENTS,
  payload: {
    request: {
      method: 'GET',
      url: `registries/${documentId}/comments`
    }
  }
});

export const commentRegistry = (data, documentId) => ({
  type: COMMENT_REGISTRY,
  payload: {
    request: {
      method: 'POST',
      url: `registries/${documentId}/comments`,
      data
    }
  }
});

export const fetchRegistryHistory = (documentId) => ({
  type: FETCH_REGISTRY_HISTORY,
  payload: {
    request: {
      method: 'GET',
      url: `registries/${documentId}/reject-attempts`
    }
  }
});
