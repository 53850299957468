
export const LOAD_MORE_SUPPLY_UPLOAD = 'LOAD_MORE_SUPPLY_UPLOAD';
export const CANCEL_SUPPLY_UPLOAD = 'CANCEL_SUPPLY_UPLOAD';
export const SUPPLY_UPLOAD = 'SUPPLY_UPLOAD';
export const SUPPLY_PRE_UPLOAD = 'SUPPLY_PRE_UPLOAD';

export const loadMoreSupplyUpload = ({ hash, ...params }) => ({
  type: LOAD_MORE_SUPPLY_UPLOAD,
  payload: {
    request: {
      method: 'GET',
      url: `supply/upload/${hash}`,
      params
    }
  }
});

export const cancelSupplyUpload = payload => ({
  type: CANCEL_SUPPLY_UPLOAD,
  payload
});

export const supplyPreUpload = ({ hash, ...params }) => ({
  type: SUPPLY_PRE_UPLOAD,
  payload: {
    request: {
      method: 'GET',
      url: `supply/upload/${hash}`,
      params
    }
  }
});

export const supplyUpload = ({ hash, ...data }) => ({
  type: SUPPLY_UPLOAD,
  payload: {
    request: {
      method: 'POST',
      url: `supply/upload/${hash}`,
      data
    }
  }
});
