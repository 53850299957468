import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const checkPermissions = (userPermissions, allowedPermissions) => {
  const [target, permission] = allowedPermissions.split(':');
  if (!allowedPermissions) return true;
  return target in userPermissions && permission in userPermissions[target];
};

/**
 * Transform Permissions from Array to Object.
 *
 * permissionArray = [{
 *   documentType: "REGISTRY",
 *   privilegeList: ["CREATE", "READ", "SIGN"]
 * }]
 *
 * permissionObject = {
 *   REGISTRY: {
 *     CREATE: true,
 *     READ: true,
 *     SIGN: true
 *   }
 * }
 *
 * @param {Array} data - permissionArray.
 * @returns {Object} permissionObject
 */
const transformPermissions = data => data.reduce(
  (prevPer, { documentType, privilegeList }) => ({
    ...prevPer,
    [documentType]: privilegeList.reduce((prevPriv, current) => ({ ...prevPriv, [current]: true }), {}),
  }),
  {}
);

const AccessControl = ({ allowedPermissions, userPermissions, renderNoAccess, children }) => {
  const permitted = checkPermissions(userPermissions, allowedPermissions);
  if (permitted) return children;
  return renderNoAccess();
};

AccessControl.propTypes = {
  allowedPermissions: PropTypes.string,
  userPermissions: PropTypes.object,
  renderNoAccess: PropTypes.func,
};

AccessControl.defaultProps = {
  allowedPermissions: '',
  userPermissions: null,
  renderNoAccess: () => null,
};

const mapStateToProps = state => ({ userPermissions: transformPermissions(state.user.permissionList) });

export default connect(mapStateToProps, null)(AccessControl);
