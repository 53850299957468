import {
  NEW_FACTORING_NOTIFICATION,
  CREATE_NEW_FACTORING_NOTIFICATION,
  CANCEL_NEW_FACTORING_NOTIFICATION
} from './FactoringNotificationNew.actions';

const initialState = {
  isFetching: false,
  isError: false,
  error: null
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case NEW_FACTORING_NOTIFICATION:
      return {
        ...initialState,
        ...payload
      };

    case CANCEL_NEW_FACTORING_NOTIFICATION:
      return { ...initialState };

    case CREATE_NEW_FACTORING_NOTIFICATION:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${CREATE_NEW_FACTORING_NOTIFICATION}_SUCCESS`:
      return {
        ...initialState,
        isFetching: false,
        isError: false,
        error: null
      };

    case `${CREATE_NEW_FACTORING_NOTIFICATION}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    default:
      return state;
  }
}
