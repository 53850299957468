export const NEW_FACTORING_NOTIFICATION = 'NEW_FACTORING_NOTIFICATION';
export const CANCEL_NEW_FACTORING_NOTIFICATION = 'CANCEL_NEW_FACTORING_NOTIFICATION';
export const CREATE_NEW_FACTORING_NOTIFICATION = 'CREATE_NEW_FACTORING_NOTIFICATION';

export const createNewFactoringNotification = data => ({
  type: CREATE_NEW_FACTORING_NOTIFICATION,
  payload: {
    request: {
      method: 'POST',
      url: 'factoring-notifications',
      data
    }
  }
});

export const cancelNewFactoringNotification = payload => ({
  type: CANCEL_NEW_FACTORING_NOTIFICATION,
  payload
});
