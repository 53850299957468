import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import { LOGIN, LOGOUT } from '../actions/auth.actions';
import { FETCH_USER } from '../actions/user.actions';

import { JivoSiteApiInstance } from '../utils/jivoSiteApi';

const initialState = {
  isFetching: false,
  isError: false,
  isAuthenticated: false,
  error: null,
};

/** @type {import('redux-persist').PersistConfig} */
const persistConfig = {
  key: 'auth',
  storage,
  whitelist: ['isAuthenticated'],
};

function authReducer(state = initialState, { type, payload, error }) {
  switch (type) {
    case LOGIN:
      return {
        ...state,
        isFetching: true,
        isError: false
      };

    case `${LOGIN}_SUCCESS`:
      JivoSiteApiInstance.setContactInfo(payload.data);
      return {
        ...state,
        isAuthenticated: true,
        isFetching: false,
        isError: false
      };

    case `${LOGIN}_FAIL`:
      return {
        isAuthenticated: false,
        isFetching: false,
        isError: true,
        error: { ...error.response.data }
      };

    case FETCH_USER:
      return {
        ...state,
        isFetching: true,
        isError: false
      };

    case `${FETCH_USER}_SUCCESS`:
      return {
        ...state,
        isAuthenticated: true,
        isFetching: false,
        isError: false
      };

    case `${FETCH_USER}_FAIL`:
      return {
        isAuthenticated: false,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case LOGOUT:
      return {
        ...initialState,
        isFetching: true
      };

    case `${LOGOUT}_SUCCESS`:
      return { ...initialState };

    case `${LOGOUT}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    default:
      return state;
  }
}

export default persistReducer(persistConfig, authReducer);
