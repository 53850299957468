export const FETCH_FACTORING_CONTRACTS = 'FETCH_FACTORING_CONTRACTS';
export const LOAD_MORE_FACTORING_CONTRACTS = 'LOAD_MORE_FACTORING_CONTRACTS';
export const UPDATE_FACTORING_CONTRACTS_FILTERS = 'UPDATE_FACTORING_CONTRACTS_FILTERS';
export const CLEAR_FACTORING_CONTRACTS_FILTERS = 'CLEAR_FACTORING_CONTRACTS_FILTERS';
export const SET_FACTORING_CONTRACTS_SORTING = 'SET_FACTORING_CONTRACTS_SORTING';

export const fetchFactoringContracts = (params = {}) => ({
  type: FETCH_FACTORING_CONTRACTS,
  payload: {
    request: {
      method: 'GET',
      url: 'factoring-contracts',
      params
    }
  }
});

export const loadMoreFactoringContracts = (params = {}) => ({
  type: LOAD_MORE_FACTORING_CONTRACTS,
  payload: {
    request: {
      method: 'GET',
      url: 'factoring-contracts',
      params
    }
  }
});

export const updateFactoringContractsFilters = payload => dispatch => dispatch({
  type: UPDATE_FACTORING_CONTRACTS_FILTERS,
  payload
});

export const clearFactoringContractsFilters = payload => dispatch => dispatch({
  type: CLEAR_FACTORING_CONTRACTS_FILTERS,
  payload
});

export const setFactoringContractsSort = payload => dispatch => dispatch({
  type: SET_FACTORING_CONTRACTS_SORTING,
  payload
});
