import { FETCH_BADGES } from '../actions/badges.actions';
import { LOGOUT } from '../actions/auth.actions';

const initialState = {
  isFetching: false,
  isError: false,
  data: {
    registryCount: 0,
    factoringNotificationCount: 0,
    factoringContractCount: 0,
    factoringRequestCount: 0
  }
};

export default function badgesReducer(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_BADGES:
      return {
        ...state,
        isFetching: true,
        isError: false,
      };

    case `${FETCH_BADGES}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        data: { ...state.data, ...payload.data }
      };

    case `${FETCH_BADGES}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case `${LOGOUT}_SUCCESS`:
      return { ...initialState };

    default:
      return state;
  }
}
