import React, { Component } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import eq from 'lodash/eq';

import { Loader } from '../../ui-components/Loader';

import { fetchUser } from '../../actions/user.actions';

class PrivateRoute extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
  }

  async componentDidMount() {
    const { isAuthenticated } = this.props;
    this.setState({ isLoading: true });
    try {
      if (!isAuthenticated) await this.props.onFetchUser();
      this.setState({ isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
    }
  }

  static getPropsEqualCriteria(props) {
    return {
      isAuthenticated: props.isAuthenticated,
      location: props.location,
    };
  }

  static isPropsEqual(left, right) {
    const leftCriteria = PrivateRoute.getPropsEqualCriteria(left);
    const rightCriteria = PrivateRoute.getPropsEqualCriteria(right);

    return eq(leftCriteria, rightCriteria);
  }

  shouldComponentUpdate(nextProps) {
    return !PrivateRoute.isPropsEqual(this.props, nextProps);
  }

  render() {
    const { component: C, path, isAuthenticated, ...rest } = this.props;
    const { isLoading } = this.state;

    if (isLoading) return <Loader isFullSize />;

    return (
      <Route
        path={path}
        {...rest}
        render={props => (isAuthenticated ? <C {...rest} {...props} /> : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />)} />
    );
  }
}

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  onFetchUser: PropTypes.func.isRequired,
  component: PropTypes.func,
  isAuthenticated: PropTypes.bool,
};

PrivateRoute.defaultProps = {
  component: f => f,
  isAuthenticated: false,
};

const mapStateToProps = state => ({ isAuthenticated: state.auth.isAuthenticated });

const mapDispatchToProps = (dispatch, actions = {}) => {
  actions.onFetchUser = fetchUser;
  return bindActionCreators(actions, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateRoute));
