import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  FETCH_FACTORING_REQUESTS,
  UPDATE_FACTORING_REQUESTS_FILTERS,
  CLEAR_FACTORING_REQUESTS_FILTERS,
  LOAD_MORE_FACTORING_REQUESTS, SET_FACTORING_REQUESTS_SORT
} from './FactoringRequests.actions';
import { LOGOUT } from '../../shared/actions/auth.actions';

const initialState = {
  isFetching: false,
  isError: false,
  error: null,
  data: {
    records: [],
    isLast: true,
    numberOfElements: 25,
    totalElements: 0,
    totalPages: 0,
  },
  filters: {
    debtorIdList: [],
    statusList: [],
    supplyContractIdList: [],
    supplierIdList: [],
  },
  sorting: {
    direction: '',
    by: '',
  },
};

/** @type {import('redux-persist').PersistConfig} */
const persistConfig = {
  storage,
  key: 'factoring-requests-list-cfg',
  whitelist: ['filters', 'sorting'],
};

function FactoringRequestsListReducer(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_FACTORING_REQUESTS:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${FETCH_FACTORING_REQUESTS}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        data: { ...state.data, ...payload.data }
      };

    case `${FETCH_FACTORING_REQUESTS}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case UPDATE_FACTORING_REQUESTS_FILTERS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        filters: {
          ...state.filters,
          ...payload
        },
      };

    case CLEAR_FACTORING_REQUESTS_FILTERS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        filters: payload.type === 'all'
          ? { ...initialState.filters }
          : {
            ...state.filters,
            ...{ [payload.type]: initialState.filters[payload.type] }
          }
      };

    case LOAD_MORE_FACTORING_REQUESTS:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${LOAD_MORE_FACTORING_REQUESTS}_SUCCESS`:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.data,
          records: [
            ...state.data.records,
            ...payload.data.records
          ]
        },
        isFetching: false,
        isError: false,
        error: null
      };

    case `${LOAD_MORE_FACTORING_REQUESTS}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case SET_FACTORING_REQUESTS_SORT:
      return {
        ...state,
        sorting: payload,
      };

    case LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
}

export default persistReducer(persistConfig, FactoringRequestsListReducer);
