export const FETCH_SUPPLY_CONTRACT_BY_ID = 'FETCH_SUPPLY_CONTRACT_BY_ID';
export const FETCH_OFFERS_AVAILABILITY_CONDITIONS = 'FETCH_OFFERS_AVAILABILITY_CONDITIONS';


export const fetchSupplyContractById = documentId => ({
  type: FETCH_SUPPLY_CONTRACT_BY_ID,
  payload: {
    request: {
      method: 'GET',
      url: `supply-contracts/${documentId}`
    }
  }
});
