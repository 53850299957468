import {
  FETCH_DEBTOR_LIST,
  FETCH_FACTOR_LIST,
  FETCH_SUPPLIER_LIST
} from '../actions/companies.actions';

const initialState = {
  isFetching: false,
  isError: false,
  error: null,
  data: {
    debtorList: [],
    factorList: [],
    supplierList: []
  }
};

export default function companiesReducer(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_DEBTOR_LIST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${FETCH_DEBTOR_LIST}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        data: {
          ...state.data,
          debtorList: [...payload.data.records]
        }
      };

    case `${FETCH_DEBTOR_LIST}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case FETCH_FACTOR_LIST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${FETCH_FACTOR_LIST}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        data: {
          ...state.data,
          factorList: [...payload.data.records]
        }
      };

    case `${FETCH_FACTOR_LIST}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case FETCH_SUPPLIER_LIST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${FETCH_SUPPLIER_LIST}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        data: {
          ...state.data,
          supplierList: [...payload.data.records]
        }
      };

    case `${FETCH_SUPPLIER_LIST}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    default:
      return state;
  }
}
