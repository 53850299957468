/**
 * @typedef ActionChainConfigItem
 * @property onSuccess {boolean}
 * @property source {string | string[]}
 * @property target {Function | Function[]}
 */

/**
 * @param item {ActionChainConfigItem}
 * @returns {Record<string, Function>}
 */
const transformConfigItem = item => {
  const target = Array.isArray(item.target) ? [...item.target] : [item.target];
  /** @type {string[]} */
  let source = Array.isArray(item.source) ? [...item.source] : [item.source];
  if (item.onSuccess) {
    source = source.map(name => `${name}_SUCCESS`);
  }

  const configChunk = {};
  for (const sourceName of source) {
    for (const targetName of target) {
      configChunk[sourceName] = targetName;
    }
  }

  return configChunk;
};

/**
 * Small actions binding middleware
 * @param config {ActionChainConfigItem | ActionChainConfigItem[]}
 * @returns {import('redux').Middleware}
 */
export const chainMiddleware = config => {
  const flatConfig = Array.isArray(config)
    ? config.reduce((acc, item) => Object.assign(acc, transformConfigItem(item)), {})
    : transformConfigItem(config);

  return ({ dispatch }) => next => action => {
    const { type } = action;
    if (type in flatConfig) {
      const chainAction = flatConfig[action.type];
      dispatch(chainAction());
    }

    return next(action);
  };
};
