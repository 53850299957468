export const GET_USER_LOGIN = 'GET_USER_LOGIN';
export const SET_PASSWORD = 'SET_PASSWORD';

export const getUserLogin = token => ({
  type: GET_USER_LOGIN,
  payload: {
    request: {
      method: 'GET',
      url: `auth/change-password-user/${token}`,
    }
  }
});

export const setPassword = data => ({
  type: SET_PASSWORD,
  payload: {
    request: {
      method: 'PATCH',
      url: 'auth/change-password-user',
      data
    }
  }
});
