export const FETCH_FACTORING_CONTRACT_BY_ID = 'FETCH_FACTORING_CONTRACT_BY_ID';
export const FETCH_FACTORING_CONTRACT_TIMELINE_BY_ID = 'FETCH_FACTORING_CONTRACT_TIMELINE_BY_ID';
export const SIGN_FACTORING_CONTRACT = 'SIGN_FACTORING_CONTRACT';
export const FINISH_FACTORING_CONTRACT = 'FINISH_FACTORING_CONTRACT';
export const FETCH_FACTORING_CONTRACT_COMMENTS = 'FETCH_FACTORING_CONTRACT_COMMENTS';
export const COMMENT_FACTORING_CONTRACT = 'COMMENT_FACTORING_CONTRACT';
export const FETCH_SUPPLY_CONTRACTS_BY_FACTOR_CONTRACT = 'FETCH_SUPPLY_CONTRACTS_BY_FACTOR_CONTRACT';


export const fetchFactoringContractById = documentId => ({
  type: FETCH_FACTORING_CONTRACT_BY_ID,
  payload: {
    request: {
      method: 'GET',
      url: `factoring-contracts/${documentId}`
    }
  }
});

export const fetchSupplyContractsByFactorContract = documentId => ({
  type: FETCH_SUPPLY_CONTRACTS_BY_FACTOR_CONTRACT,
  payload: {
    request: {
      method: 'GET',
      url: `factoring-contracts/${documentId}/supply-contracts`
    }
  }
});

export const finishFactoringContract = ({ documentId, data = {} }) => ({
  type: FINISH_FACTORING_CONTRACT,
  payload: {
    request: {
      method: 'POST',
      url: `factoring-contracts/${documentId}/finish`,
      data
    }
  }
});

export const signFactoringContract = (data, documentId) => ({
  type: SIGN_FACTORING_CONTRACT,
  payload: {
    request: {
      method: 'PATCH',
      url: `factoring-contracts/${documentId}`,
      data
    }
  }
});

export const fetchFactoringContractComments = documentId => ({
  type: FETCH_FACTORING_CONTRACT_COMMENTS,
  payload: {
    request: {
      method: 'GET',
      url: `factoring-contracts/${documentId}/comments`
    }
  }
});

export const commentFactoringContract = (data, documentId) => ({
  type: COMMENT_FACTORING_CONTRACT,
  payload: {
    request: {
      method: 'POST',
      url: `factoring-contracts/${documentId}/comments`,
      data
    }
  }
});

export const fetchFactoringContractTimelineById = documentId => ({
  type: FETCH_FACTORING_CONTRACT_TIMELINE_BY_ID,
  payload: {
    request: {
      method: 'GET',
      url: `factoring-contracts/${documentId}/timeline`
    }
  }
});
