export const FETCH_USER = 'FETCH_USER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SEND_INVITE = 'SEND_INVITE';

export const fetchUser = () => ({
  type: FETCH_USER,
  payload: {
    request: {
      method: 'GET',
      url: 'users/me'
    }
  }
});

export const setUser = payload => ({
  type: SET_CURRENT_USER,
  payload
});

export const sendResetPasswordLink = login => ({
  type: SEND_INVITE,
  payload: {
    request: {
      method: 'POST',
      url: 'auth/forgot-password',
      data: { login }
    }
  }
});
