import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { ErrorPage } from '../shared/components/ErrorPage';
import SupportNotifications from '../shared/components/SupportNotifications';
import AccessControlRoute from '../shared/components/AccessControlRoute';
import Notifications from '../shared/components/Notifications';
import PrivateRoute from '../shared/components/PrivateRoute';

import { pipe } from '../shared/utils/fp';

import { ROLES } from '../shared/constants/roles';
import { LoadableRoutes } from './LoadableRoutes';

const App = ({ userRole }) => (
  <>
    <SupportNotifications />
    <Switch>
      <PrivateRoute exact path="/supply" component={LoadableRoutes.Supply} />
      <PrivateRoute exact path="/supply/upload" component={LoadableRoutes.SupplyUpload} />
      <AccessControlRoute exact path="/supply/:id" allowedPermissions="SUPPLY:READ" component={LoadableRoutes.SupplyInfo} />
      {userRole !== ROLES.FACTOR && <PrivateRoute exact path="/supply-contracts" component={LoadableRoutes.SupplyContracts} />}
      {userRole !== ROLES.FACTOR && <PrivateRoute exact path="/supply-contracts/:id" component={LoadableRoutes.SupplyContractInfo} />}
      <AccessControlRoute
        exact
        path="/factoring-contracts"
        allowedPermissions="FACTORING_CONTRACT:READ"
        component={LoadableRoutes.FactoringContracts} />
      <AccessControlRoute
        exact
        path="/factoring-contracts/new"
        allowedPermissions="FACTORING_CONTRACT:CREATE"
        component={LoadableRoutes.FactoringContractNew} />
      <AccessControlRoute
        exact
        path="/factoring-contracts/:id"
        allowedPermissions="FACTORING_CONTRACT:READ"
        component={LoadableRoutes.FactoringContractInfo} />
      <AccessControlRoute exact path="/registries" allowedPermissions="REGISTRY:READ" component={LoadableRoutes.Registries} />
      <AccessControlRoute exact path="/registries/new" allowedPermissions="REGISTRY:CREATE" component={LoadableRoutes.RegistryNew} />
      <AccessControlRoute exact path="/registries/:id" allowedPermissions="REGISTRY:READ" component={LoadableRoutes.RegistryInfo} />
      <AccessControlRoute
        exact
        path="/factoring-notifications"
        allowedPermissions="FACTORING_NOTIFICATION:READ"
        component={LoadableRoutes.FactoringNotifications} />
      <AccessControlRoute
        path="/factoring-notifications/new"
        allowedPermissions="FACTORING_NOTIFICATION:CREATE"
        component={LoadableRoutes.FactoringNotificationNew} />
      <AccessControlRoute
        exact
        path="/factoring-notifications/:id"
        allowedPermissions="FACTORING_NOTIFICATION:READ"
        component={LoadableRoutes.FactoringNotificationInfo} />
      {userRole !== ROLES.DEBTOR && (
        <AccessControlRoute
          exact
          path="/factoring-requests"
          allowedPermissions="FACTORING_REQUEST:READ"
          component={LoadableRoutes.FactoringRequests} />
      )}
      {userRole !== ROLES.DEBTOR && (
        <AccessControlRoute
          exact
          path="/factoring-requests/new"
          allowedPermissions="FACTORING_REQUEST:CREATE"
          component={LoadableRoutes.FactoringRequestNew} />
      )}
      <AccessControlRoute
        exact
        path="/factoring-requests/:id"
        allowedPermissions="FACTORING_REQUEST:READ"
        component={LoadableRoutes.FactoringRequestInfo} />
      <AccessControlRoute exact path="/factoring-requests/:id/offers/new" allowedPermissions="OFFER:CREATE" component={LoadableRoutes.OfferNew} />
      <PrivateRoute exact path="/settings" component={LoadableRoutes.Settings} />
      <PrivateRoute exact path="/help" component={LoadableRoutes.Help} />
      <Route path="/registration/:hash" component={LoadableRoutes.Registration} />
      <Route path="/registration-success" component={LoadableRoutes.RegistrationSuccess} />
      <Route path="/restore-password" component={LoadableRoutes.RestorePassword} />
      <Route path="/login" component={LoadableRoutes.Login} />
      <Route path="/error/:code" render={({ match }) => <ErrorPage statusCode={+match.params.code} theme="mf" />} />
      <Redirect exact from="/" to="/supply" />
      <Redirect to="/error/404" />
    </Switch>
    <Notifications />
  </>
);

App.propTypes = { userRole: PropTypes.string.isRequired };

const mapStateToProps = state => ({ userRole: state.user.role });

export default pipe(
  App,
  connect(mapStateToProps, null),
  withRouter,
  hot(module)
);
