export const FETCH_FACTORING_REQUESTS = 'FETCH_FACTORING_REQUESTS';
export const UPDATE_FACTORING_REQUESTS_FILTERS = 'UPDATE_FACTORING_REQUESTS_FILTERS';
export const CLEAR_FACTORING_REQUESTS_FILTERS = 'CLEAR_FACTORING_REQUESTS_FILTERS';
export const LOAD_MORE_FACTORING_REQUESTS = 'LOAD_MORE_FACTORING_REQUESTS';
export const SET_FACTORING_REQUESTS_SORT = 'SET_FACTORING_REQUESTS_SORT';

export const fetchFactoringRequests = (params = {}) => ({
  type: FETCH_FACTORING_REQUESTS,
  payload: {
    request: {
      method: 'GET',
      url: 'factoring-requests',
      params
    }
  }
});

export const updateFactoringRequestsFilters = payload => dispatch => dispatch({
  type: UPDATE_FACTORING_REQUESTS_FILTERS,
  payload
});

export const clearFactoringRequestsFilters = payload => dispatch => dispatch({
  type: CLEAR_FACTORING_REQUESTS_FILTERS,
  payload
});

export const loadMoreFactoringRequests = (params = {}) => ({
  type: LOAD_MORE_FACTORING_REQUESTS,
  payload: {
    request: {
      method: 'GET',
      url: 'factoring-requests',
      params
    }
  }
});

export const setFactoringRequestsSort = payload => dispatch => dispatch({
  type: SET_FACTORING_REQUESTS_SORT,
  payload
});
