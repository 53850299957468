import { FETCH_SUPPLY_BY_ID } from './SupplyInfo.actions';

const initialState = {
  isFetching: false,
  isError: false,
  error: null,
  data: {}
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_SUPPLY_BY_ID:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${FETCH_SUPPLY_BY_ID}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        data: { ...state.data, ...payload.data }
      };

    case `${FETCH_SUPPLY_BY_ID}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    default:
      return state;
  }
}

