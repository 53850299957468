export const FETCH_REGISTRIES = 'FETCH_REGISTRIES';
export const LOAD_MORE_REGISTRIES = 'LOAD_MORE_REGISTRIES';
export const UPDATE_REGISTRIES_FILTERS = 'UPDATE_REGISTRIES_FILTERS';
export const CLEAR_REGISTRIES_FILTERS = 'CLEAR_REGISTRIES_FILTERS';
export const SET_REGISTRIES_SORTING = 'SET_REGISTRIES_SORTING';

export const fetchRegistries = (params = {}) => ({
  type: FETCH_REGISTRIES,
  payload: {
    request: {
      method: 'GET',
      url: 'registries',
      params,
    },
  },
});

export const loadMoreRegistries = (params = {}) => ({
  type: LOAD_MORE_REGISTRIES,
  payload: {
    request: {
      method: 'GET',
      url: 'registries',
      params,
    },
  },
});

export const updateRegistriesFilters = payload => dispatch => dispatch({
  type: UPDATE_REGISTRIES_FILTERS,
  payload,
});

export const clearRegistriesFilters = payload => dispatch => dispatch({
  type: CLEAR_REGISTRIES_FILTERS,
  payload,
});

export const setRegistriesSorting = payload => dispatch => dispatch({
  type: SET_REGISTRIES_SORTING,
  payload,
});
