export const FETCH_FACTORING_NOTIFICATIONS = 'FETCH_FACTORING_NOTIFICATIONS';
export const LOAD_MORE_FACTORING_NOTIFICATIONS = 'LOAD_MORE_FACTORING_NOTIFICATIONS';
export const UPDATE_FACTORING_NOTIFICATIONS_FILTERS = 'UPDATE_FACTORING_NOTIFICATIONS_FILTERS';
export const CLEAR_FACTORING_NOTIFICATIONS_FILTERS = 'CLEAR_FACTORING_NOTIFICATIONS_FILTERS';
export const SET_FACTORING_NOTIFICATIONS_SORT = 'SET_FACTORING_NOTIFICATIONS_SORT';

export const fetchFactoringNotifications = (params = {}) => ({
  type: FETCH_FACTORING_NOTIFICATIONS,
  payload: {
    request: {
      method: 'GET',
      url: 'factoring-notifications',
      params
    }
  }
});

export const loadMoreFactoringNotifications = (params = {}) => ({
  type: LOAD_MORE_FACTORING_NOTIFICATIONS,
  payload: {
    request: {
      method: 'GET',
      url: 'factoring-notifications',
      params
    }
  }
});

export const updateFactoringNotificationsFilters = payload => dispatch => dispatch({
  type: UPDATE_FACTORING_NOTIFICATIONS_FILTERS,
  payload
});

export const clearFactoringNotificationsFilters = payload => dispatch => dispatch({
  type: CLEAR_FACTORING_NOTIFICATIONS_FILTERS,
  payload
});

export const setFactoringNotificationsSort = payload => dispatch => dispatch({
  type: SET_FACTORING_NOTIFICATIONS_SORT,
  payload
});
