import {
  LOAD_MORE_SUPPLY_UPLOAD,
  CANCEL_SUPPLY_UPLOAD,
  SUPPLY_PRE_UPLOAD,
  SUPPLY_UPLOAD
} from './SupplyUpload.actions';
import { LOGOUT } from '../../shared/actions/auth.actions';

const initialState = {
  isFetching: false,
  isError: false,
  error: {
    records: [],
    isLast: true
  },
  data: {
    records: [],
    isLast: true
  },
};

export default function (state = initialState, { type, payload, ...rest }) {
  switch (type) {

    case CANCEL_SUPPLY_UPLOAD:
      return { ...initialState };

    case LOAD_MORE_SUPPLY_UPLOAD:
      return {
        ...state,
        isFetching: true
      };

    case `${LOAD_MORE_SUPPLY_UPLOAD}_SUCCESS`:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.data,
          records: [
            ...state.data.records,
            ...payload.data.records
          ]
        },
        isFetching: false,
        isError: false,
        error: initialState.error
      };

    case `${LOAD_MORE_SUPPLY_UPLOAD}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: {
          ...state.error,
          ...rest.error.response.data,
          records: [
            ...state.error.records,
            ...rest.error.response.data.records
          ]
        }
      };

    case SUPPLY_PRE_UPLOAD:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: initialState.error
      };

    case `${SUPPLY_PRE_UPLOAD}_SUCCESS`:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.data,
          records: [
            ...payload.data.records
          ]
        },
        isFetching: false,
        isError: false,
        error: initialState.error
      };

    case `${SUPPLY_PRE_UPLOAD}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...rest.error.response.data },
        data: { ...initialState.data },
      };

    case SUPPLY_UPLOAD:
      return {
        ...state,
        isFetching: true,
        isError: false
      };

    case `${SUPPLY_UPLOAD}_SUCCESS`:
      return { ...initialState };

    case `${SUPPLY_UPLOAD}_FAIL`:
      return { ...initialState };

    case LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
}
