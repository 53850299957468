import { GET_USER_LOGIN, SET_PASSWORD } from '../actions/registraction.actions';

const initialState = {
  isFetching: false,
  isError: false,
  login: null,
  error: null
};

export default function registrationReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_USER_LOGIN:
      return {
        ...state,
        isFetching: true,
        isError: false,
      };

    case `${GET_USER_LOGIN}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        login: payload.data.login
      };

    case `${GET_USER_LOGIN}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case SET_PASSWORD:
      return {
        ...state,
        isFetching: true,
        isError: false
      };

    case `${SET_PASSWORD}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false
      };

    case `${SET_PASSWORD}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    default:
      return state;
  }
}
