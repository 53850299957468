import {
  FETCH_FACTORING_NOTIFICATION_BY_ID,
  FETCH_FACTORING_NOTIFICATION_TIMELINE_BY_ID,
  SIGN_FACTORING_NOTIFICATION,
  COMMENT_FACTORING_NOTIFICATION,
  FETCH_FACTORING_NOTIFICATION_COMMENTS
} from './FactoringNotificationInfo.actions';

const initialState = {
  isFetching: false,
  isError: false,
  error: null,
  data: {},
  timeline: {},
  comments: { records: [] }
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_FACTORING_NOTIFICATION_BY_ID:
      return {
        ...initialState,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${FETCH_FACTORING_NOTIFICATION_BY_ID}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        data: {
          ...state.data,
          ...payload.data
        }
      };

    case `${FETCH_FACTORING_NOTIFICATION_BY_ID}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case SIGN_FACTORING_NOTIFICATION:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${SIGN_FACTORING_NOTIFICATION}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        data: payload.data.error ? { ...state.data } : { ...payload.data }
      };

    case `${SIGN_FACTORING_NOTIFICATION}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case FETCH_FACTORING_NOTIFICATION_COMMENTS:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${FETCH_FACTORING_NOTIFICATION_COMMENTS}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        comments: { ...payload.data }
      };

    case `${FETCH_FACTORING_NOTIFICATION_COMMENTS}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case COMMENT_FACTORING_NOTIFICATION:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${COMMENT_FACTORING_NOTIFICATION}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        comments: { records: [payload.data] }
      };

    case `${COMMENT_FACTORING_NOTIFICATION}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case FETCH_FACTORING_NOTIFICATION_TIMELINE_BY_ID:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${FETCH_FACTORING_NOTIFICATION_TIMELINE_BY_ID}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        timeline: { ...payload.data }
      };

    case `${FETCH_FACTORING_NOTIFICATION_TIMELINE_BY_ID}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    default:
      return state;
  }
}
