export const NEW_FACTORING_CONTRACT = 'NEW_FACTORING_CONTRACT';
export const UPDATE_NEW_FACTORING_CONTRACT = 'UPDATE_NEW_FACTORING_CONTRACT';
export const CANCEL_NEW_FACTORING_CONTRACT = 'CANCEL_NEW_FACTORING_CONTRACT';
export const CREATE_NEW_FACTORING_CONTRACT = 'CREATE_NEW_FACTORING_CONTRACT';
export const NEW_FACTORING_CONTRACT_FETCH_SUPPLIER_LIST = 'NEW_FACTORING_CONTRACT_FETCH_SUPPLIER_LIST';
export const NEW_FACTORING_CONTRACT_LOAD_MORE_SUPPLIERS = 'NEW_FACTORING_CONTRACT_LOAD_MORE_SUPPLIERS';
export const NEW_FACTORING_CONTRACT_FETCH_SUPPLY_CONTRACTS = 'NEW_FACTORING_CONTRACT_FETCH_SUPPLY_CONTRACTS';
export const NEW_FACTORING_CONTRACT_LOAD_MORE_SUPPLY_CONTRACTS = 'NEW_FACTORING_CONTRACT_LOAD_MORE_SUPPLY_CONTRACTS';

export const fetchSupplierList = (params = {}) => {
  const companyType = 'SUPPLIER';
  return ({
    type: `NEW_FACTORING_CONTRACT_FETCH_${companyType}_LIST`,
    payload: {
      request: {
        method: 'GET',
        url: 'companies',
        params: {
          ...params,
          filter: {
            roles: [companyType],
            companyStatus: ['ACTIVE']
          }
        }
      }
    }
  });
};

export const loadMoreSuppliers = (params = {}) => {
  const companyType = 'SUPPLIER';
  return ({
    type: NEW_FACTORING_CONTRACT_LOAD_MORE_SUPPLIERS,
    payload: {
      request: {
        method: 'GET',
        url: 'companies',
        params: {
          ...params,
          filter: {
            roles: [companyType],
            companyStatus: ['ACTIVE']
          }
        }
      }
    }
  });
};

export const fetchSupplyContractsList = (params = {}) => ({
  type: NEW_FACTORING_CONTRACT_FETCH_SUPPLY_CONTRACTS,
  payload: {
    request: {
      method: 'GET',
      url: 'supply-contracts',
      params
    }
  }
});

export const loadMoreSupplyContracts = (params = {}) => ({
  type: NEW_FACTORING_CONTRACT_LOAD_MORE_SUPPLY_CONTRACTS,
  payload: {
    request: {
      method: 'GET',
      url: 'supply-contracts',
      params
    }
  }
});

export const updateNewFactoringContract = payload => ({
  type: UPDATE_NEW_FACTORING_CONTRACT,
  payload
});

export const createNewFactoringContract = payload => ({
  type: CREATE_NEW_FACTORING_CONTRACT,
  payload: {
    request: {
      method: 'POST',
      url: 'factoring-contracts',
      data: payload
    }
  }
});

export const cancelNewFactoringContract = payload => ({
  type: CANCEL_NEW_FACTORING_CONTRACT,
  payload
});
