import React, { createRef, PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import { fetchSupportNotifications } from '../../actions/support-notifications.actions';

import styles from './SupportNotifications.module.scss';

class SupportNotifications extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      wrapperRef: createRef(),
      rootElement: null,
    };
  }

  componentDidMount() {
    this.props.fetchSupportNotifications();
    this.setState(
      { rootElement: document.querySelector(':root') },
      () => this.setNotificationHeightVariable()
    );
  }

  componentDidUpdate() {
    this.setNotificationHeightVariable();
  }

  setNotificationHeightVariable = () => {
    const { rootElement, wrapperRef } = this.state;

    if (wrapperRef.current === null) return;

    rootElement.style.setProperty('--notificationsHeight', `${wrapperRef.current.clientHeight}px`);
  };

  render() {
    const { notificationList } = this.props.supportNotifications;
    const { wrapperRef } = this.state;

    if (notificationList.length === 0) return null;

    return (
      <div className={styles.wrapper} ref={wrapperRef}>
        <div className={styles.list}>
          {notificationList.map(item => (
            <div key={item.id} className={classnames(styles.item, item.type.toLowerCase())}>
              {item.message}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

SupportNotifications.propTypes = {
  supportNotifications: PropTypes.object.isRequired,
  fetchSupportNotifications: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({ supportNotifications: state.supportNotifications });

const mapDispatchToProps = (dispatch, actions = {}) => {
  actions.fetchSupportNotifications = fetchSupportNotifications;
  return bindActionCreators(actions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportNotifications);
