import React from 'react';
import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import './assets/fonts/lato/latofonts.css';
import './assets/fonts/MegaFon Graphik Web Fonts/fonts.css';
import './assets/style/main.css';

/** Polyfills */
import './shared/polyfills/element-closest';

/** CryptoPro API */
import './assets/scripts/vendors/cadesplugin_api';

/** Store, Persistor */
import { store, persistor } from './shared/store';
import { ErrorHandler } from './shared/components/ErrorHadler';

import App from './app/App';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <ErrorHandler>
          <App />
        </ErrorHandler>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
