import axios from 'axios';
import qs from 'qs';

import decodeErrorInterceptor from '../interceptors/decodeError.axios-interceptor';

const clients = {
  default: {
    client: axios.create({
      baseURL: '/api/',
      responseType: 'json',
      headers: { 'Application-Name': window.location.origin },
      paramsSerializer: p => qs.stringify({ ...p }, { allowDots: true })
    })
  },
  fileService: {
    client: axios.create({
      baseURL: '/file-service/',
      responseType: 'json',
      headers: {
        'Application-Name': window.location.origin,
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  uploadService: {
    client: axios.create({
      baseURL: '/upload-service/',
      responseType: 'json',
      headers: {
        'Application-Name': window.location.origin,
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  dadata: {
    client: axios.create({
      baseURL: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/',
      headers: {
        Accept: 'application/json',
        Authorization: 'Token 927c59f21ae74b1b9ea397b1e89fa277e82e1399',
        'Content-Type': 'application/json'
      }
    })
  }
};

const backend = clients.default.client;
const fileService = clients.fileService.client;
const uploadService = clients.uploadService.client;
const dadata = clients.dadata.client;

// noinspection JSCheckFunctionSignatures
// backend.interceptors.response.use(...mergeInterceptors(decodeErrorInterceptor, expireSessionInterceptor));
backend.interceptors.response.use(...decodeErrorInterceptor);

export {
  backend,
  fileService,
  uploadService,
  dadata,
  clients
};
