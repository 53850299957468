export const FETCH_SUPPLY_CONTRACTS = 'FETCH_SUPPLY_CONTRACTS';
export const LOAD_MORE_SUPPLY_CONTRACTS = 'LOAD_MORE_SUPPLY_CONTRACTS';
export const UPDATE_SUPPLY_CONTRACTS_FILTERS = 'UPDATE_SUPPLY_CONTRACTS_FILTERS';
export const CLEAR_SUPPLY_CONTRACTS_FILTERS = 'CLEAR_SUPPLY_CONTRACTS_FILTERS';
export const SET_SUPPLY_CONTRACTS_SORT = 'SET_SUPPLY_CONTRACTS_SORT';

export const fetchSupplyContracts = (params = {}) => ({
  type: FETCH_SUPPLY_CONTRACTS,
  payload: {
    request: {
      method: 'GET',
      url: 'supply-contracts',
      params
    }
  }
});

export const loadMoreSupplyContracts = (params = {}) => ({
  type: LOAD_MORE_SUPPLY_CONTRACTS,
  payload: {
    request: {
      method: 'GET',
      url: 'supply-contracts',
      params
    }
  }
});

export const updateSupplyContractsFilters = payload => dispatch => dispatch({
  type: UPDATE_SUPPLY_CONTRACTS_FILTERS,
  payload
});

export const clearSupplyContractsFilters = payload => dispatch => dispatch({
  type: CLEAR_SUPPLY_CONTRACTS_FILTERS,
  payload
});

export const setSupplyContractsSort = payload => dispatch => dispatch({
  type: SET_SUPPLY_CONTRACTS_SORT,
  payload
});
