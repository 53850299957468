import { FETCH_USER, SET_CURRENT_USER } from '../actions/user.actions';
import { LOGIN, LOGOUT } from '../actions/auth.actions';

const initialState = {
  isFetching: false,
  isError: false,
  error: null,
  role: 'NONE',
  permissionList: [],
};

export default function userReducer(state = initialState, { type, payload }) {
  switch (type) {
    case `${SET_CURRENT_USER}`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null
      };

    case `${FETCH_USER}`:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${FETCH_USER}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        ...payload.data
      };

    case `${FETCH_USER}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case `${LOGIN}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        ...payload.data,
      };

    case `${LOGOUT}`:
      return { ...initialState };

    default:
      return state;
  }
}
