import { LOGOUT } from '../actions/auth.actions';

const interceptor = {
  success: (_, res) => Promise.resolve(res),
  error: (store, error) => {
    const { response: { status } } = error;

    if (status === 401) {
      localStorage.clear();
      store.dispatch({ type: `${LOGOUT}_SUCCESS` });
    }

    return Promise.reject(error);
  },
};

export default interceptor;
