import React from 'react';
import PropTypes from 'prop-types';

import { StyledNotification } from './Notification.styles';

function Notification({ notification, onRemove }) {
	return (
		<StyledNotification
			type={notification.type}
			role="button"
			tabIndex="0"
			onKeyDown={f => f}
		>
			<span
				role="button"
				tabIndex="0"
				onKeyDown={f => f}
				className="close"
				onClick={onRemove}
			/>
			{notification.title && <div className="title lato_heavy">{notification.title}</div>}
			{notification.text && <div className="text">{notification.text}</div>}
		</StyledNotification>
	);
}

Notification.propTypes = {
	notification: PropTypes.shape({
		text: PropTypes.string.isRequired,
		type: PropTypes.string,
		title: PropTypes.string,
		delay: PropTypes.number,
	}).isRequired,
	onRemove: PropTypes.func.isRequired,
};

export { Notification };
