import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  FETCH_SUPPLY,
  FETCH_SUPPLY_BY_CONTRACT_ID,
  UPDATE_SUPPLY_FILTERS,
  CLEAR_SUPPLY_FILTERS,
  LOAD_MORE_SUPPLY, SET_SUPPLY_SORTING
} from './Supply.actions';
import { LOGOUT } from '../../shared/actions/auth.actions';

const initialState = {
  isFetching: false,
  isError: false,
  error: null,
  data: {
    records: [],
    isLast: true,
    totalPages: 0,
    totalElements: 0,
  },
  filters: {
    documentNumber: '',
    registryDocumentNumber: '',
    amount: {
      from: '',
      to: '',
    },
    statusList: [],
    debtorIdList: [],
    supplierIdList: [],
    supplyContractIdList: [],
    paymentDate: {
      start: null,
      end: null
    },
    documentDate: {
      start: null,
      end: null
    },
    supplyCurrencyCodeISOCDList: []
  },
  sorting: {
    direction: '',
    by: '',
  },
};

/** @type {import('redux-persist').PersistConfig} */
const persistConfig = {
  storage,
  key: 'supply-list-cfg',
  whitelist: ['filters', 'sorting'],
};

function SupplyListReducer(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_SUPPLY:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${FETCH_SUPPLY}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        data: { ...state.data, ...payload.data }
      };

    case `${FETCH_SUPPLY}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case FETCH_SUPPLY_BY_CONTRACT_ID:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${FETCH_SUPPLY_BY_CONTRACT_ID}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        data: { ...state.data, ...payload.data }
      };

    case `${FETCH_SUPPLY_BY_CONTRACT_ID}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case UPDATE_SUPPLY_FILTERS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        filters: {
          ...state.filters,
          ...payload
        },
      };

    case CLEAR_SUPPLY_FILTERS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        filters: payload.type === 'all'
          ? { ...initialState.filters }
          : {
            ...state.filters,
            ...{ [payload.type]: initialState.filters[payload.type] }
          }
      };

    case LOAD_MORE_SUPPLY:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${LOAD_MORE_SUPPLY}_SUCCESS`:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.data,
          records: [
            ...state.data.records,
            ...payload.data.records
          ]
        },
        isFetching: false,
        isError: false,
        error: null
      };

    case `${LOAD_MORE_SUPPLY}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case SET_SUPPLY_SORTING:
      return {
        ...state,
        sorting: payload,
      };

    case LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
}

export default persistReducer(persistConfig, SupplyListReducer);
