export const FETCH_FACTORING_NOTIFICATION_BY_ID = 'FETCH_FACTORING_NOTIFICATION_BY_ID';
export const FETCH_FACTORING_NOTIFICATION_TIMELINE_BY_ID = 'FETCH_FACTORING_NOTIFICATION_TIMELINE_BY_ID';
export const EDIT_FACTORING_NOTIFICATION = 'EDIT_FACTORING_NOTIFICATION';
export const SIGN_FACTORING_NOTIFICATION = 'SIGN_FACTORING_NOTIFICATION';
export const FETCH_FACTORING_NOTIFICATION_COMMENTS = 'FETCH_FACTORING_NOTIFICATION_COMMENTS';
export const COMMENT_FACTORING_NOTIFICATION = 'COMMENT_FACTORING_NOTIFICATION';


export const fetchFactoringNotificationById = documentId => ({
  type: FETCH_FACTORING_NOTIFICATION_BY_ID,
  payload: {
    request: {
      method: 'GET',
      url: `factoring-notifications/${documentId}`
    }
  }
});

export const signFactoringNotification = ({ documentId, data }) => ({
  type: SIGN_FACTORING_NOTIFICATION,
  payload: {
    request: {
      method: 'PATCH',
      url: `factoring-notifications/${documentId}`,
      data
    }
  }
});

export const fetchFactoringNotificationTimelineById = documentId => ({
  type: FETCH_FACTORING_NOTIFICATION_TIMELINE_BY_ID,
  payload: {
    request: {
      method: 'GET',
      url: `factoring-notifications/${documentId}/timeline`
    }
  }
});

export const fetchFactoringNotificationComments = documentId => ({
  type: FETCH_FACTORING_NOTIFICATION_COMMENTS,
  payload: {
    request: {
      method: 'GET',
      url: `factoring-notifications/${documentId}/comments`
    }
  }
});

export const commentFactoringNotification = (data, documentId) => ({
  type: COMMENT_FACTORING_NOTIFICATION,
  payload: {
    request: {
      method: 'POST',
      url: `factoring-notifications/${documentId}/comments`,
      data
    }
  }
});
