export const FETCH_CURRENCIES_LIST = 'FETCH_CURRENCIES_LIST';


export const fetchCurrenciesList = (params = {}) => ({
  type: 'FETCH_CURRENCIES_LIST',
  payload: {
    request: {
      method: 'GET',
      url: 'dictionary/currencies',
      params
    }
  }
});
