export const FETCH_FACTORING_REQUEST_BY_ID = 'FETCH_FACTORING_REQUEST_BY_ID';
export const FETCH_OFFERS_BY_FACTORING_REQUEST_ID = 'FETCH_OFFERS_BY_FACTORING_REQUEST_ID';
export const FINISH_FACTORING_REQUEST_BY_ID = 'FINISH_FACTORING_REQUEST_BY_ID';
export const ACCEPT_OFFER = 'ACCEPT_OFFER';


export const fetchFactoringRequestById = documentId => ({
  type: FETCH_FACTORING_REQUEST_BY_ID,
  payload: {
    request: {
      method: 'GET',
      url: `factoring-requests/${documentId}`
    }
  }
});

export const fetchOffersByFactoringRequestId = documentId => ({
  type: FETCH_OFFERS_BY_FACTORING_REQUEST_ID,
  payload: {
    request: {
      method: 'GET',
      url: `factoring-requests/${documentId}/offers`
    }
  }
});

export const acceptOffer = (offerId, data = {}) => ({
  type: ACCEPT_OFFER,
  payload: {
    request: {
      method: 'PATCH',
      url: `offers/${offerId}`,
      data
    }
  }
});

export const finishFactoringRequestById = documentId => ({
  type: FINISH_FACTORING_REQUEST_BY_ID,
  payload: {
    request: {
      method: 'DELETE',
      url: `factoring-requests/${documentId}`
    }
  }
});
