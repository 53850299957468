class JivoSiteApi {
  constructor() {
    this.isOnLoad = false;
    this.jivoApi = null;
    window.jivo_onLoadCallback = this.onLoad.bind(this);
  }

  static transformContactInfo({ firstName, middleName, lastName, email, phoneNumber, userName }) {
    return {
      email,
      name: `${firstName} ${middleName} ${lastName}`,
      phone: phoneNumber,
      description: userName
    };
  }

  onLoad() {
    this.isOnLoad = true;
    this.jivoApi = window.jivo_api;
  }

  setContactInfo(user) {
    if (this.isOnLoad) {
      this.jivoApi.setContactInfo(JivoSiteApi.transformContactInfo(user));
    }
  }
}

const JivoSiteApiInstance = new JivoSiteApi();

export { JivoSiteApiInstance };
