import {
  NEW_OFFER,
  SEND_NEW_OFFER,
  CANCEL_SEND_NEW_OFFER,
  FETCH_FACTORING_REQUEST_FOR_OFFER_BY_ID
} from './OfferNew.actions';

const initialState = {
  isFetching: false,
  isError: false,
  error: null,
  data: {
    id: undefined,
    debtor: { companyName: '' },
    supplier: {
      inn: '',
      kpp: ''
    },
    factor: { companyName: '' },
    supplyContract: { id: 0 },
    supplyContractInfo: {
      paymentDelay: '',
      salesAmount: '',
      endingDate: 0,
      attachmentList: []
    },
    contactPersonInfo: {
      phoneNumber: '',
      email: '',
      userFullName: '',
      positionName: ''
    },
    paymentCurrency: {}
  }
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case NEW_OFFER:
      return {
        ...initialState,
        data: { ...payload }
      };

    case CANCEL_SEND_NEW_OFFER:
      return { ...initialState };

    case SEND_NEW_OFFER:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${SEND_NEW_OFFER}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null
      };

    case `${SEND_NEW_OFFER}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case FETCH_FACTORING_REQUEST_FOR_OFFER_BY_ID:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${FETCH_FACTORING_REQUEST_FOR_OFFER_BY_ID}_SUCCESS`:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.data
        },
        isFetching: false,
        isError: false,
        error: null
      };

    case `${FETCH_FACTORING_REQUEST_FOR_OFFER_BY_ID}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    default:
      return state;
  }
}
