import {
  FETCH_REGISTRY_BY_ID,
  FETCH_REGISTRY_TIMELINE_BY_ID,
  SIGN_REGISTRY,
  DELETE_REGISTRY,
  FETCH_REGISTRY_SUPPLY,
  FETCH_REGISTRY_COMMENTS,
  COMMENT_REGISTRY,
  FETCH_REGISTRY_HISTORY
} from './RegistryInfo.actions';

const initialState = {
  isFetching: false,
  isError: false,
  error: null,
  data: {},
  timeline: { recordList: [] },
  supply: { records: [] },
  comments: { records: [] },
  history: { records: [] }
};


export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_REGISTRY_BY_ID:
      return {
        ...state,
        isFetching: true
      };

    case `${FETCH_REGISTRY_BY_ID}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        data: { ...payload.data }
      };

    case `${FETCH_REGISTRY_BY_ID}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case FETCH_REGISTRY_SUPPLY:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${FETCH_REGISTRY_SUPPLY}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        supply: { ...payload.data }
      };

    case `${FETCH_REGISTRY_SUPPLY}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case SIGN_REGISTRY:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${SIGN_REGISTRY}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        data: { ...payload.data }
      };

    case `${SIGN_REGISTRY}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case COMMENT_REGISTRY:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${COMMENT_REGISTRY}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        comments: { records: [payload.data] }
      };

    case `${COMMENT_REGISTRY}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case FETCH_REGISTRY_COMMENTS:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${FETCH_REGISTRY_COMMENTS}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        comments: { ...payload.data }
      };

    case `${FETCH_REGISTRY_COMMENTS}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case DELETE_REGISTRY:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${DELETE_REGISTRY}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        data: { ...payload.data }
      };

    case `${DELETE_REGISTRY}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case FETCH_REGISTRY_TIMELINE_BY_ID:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${FETCH_REGISTRY_TIMELINE_BY_ID}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        timeline: { ...payload.data }
      };

    case `${FETCH_REGISTRY_TIMELINE_BY_ID}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case FETCH_REGISTRY_HISTORY:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${FETCH_REGISTRY_HISTORY}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        history: { ...payload.data }
      };

    case `${FETCH_REGISTRY_HISTORY}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    default:
      return state;
  }
}
