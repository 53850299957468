import { CLEAR_SUPPLY_FILTERS } from '../Supply/Supply.actions';
import { CLEAR_SUPPLY_CONTRACTS_FILTERS } from '../SupplyContracts/SupplyContracts.actions';

export const NEW_REGISTRY = 'NEW_REGISTRY';
export const UPDATE_NEW_REGISTRY = 'UPDATE_NEW_REGISTRY';
export const CANCEL_NEW_REGISTRY = 'CANCEL_NEW_REGISTRY';
export const CREATE_NEW_REGISTRY = 'CREATE_NEW_REGISTRY';

export const initNewRegistry = payload => dispatch => {
  dispatch({
    type: CLEAR_SUPPLY_FILTERS,
    payload: { type: 'all' }
  });

  dispatch({
    type: CLEAR_SUPPLY_CONTRACTS_FILTERS,
    payload: { type: 'all' }
  });

  return dispatch({
    type: NEW_REGISTRY,
    payload
  });
};

export const updateNewRegistry = payload => ({
  type: UPDATE_NEW_REGISTRY,
  payload
});

export const cancelNewRegistry = payload => dispatch => {
  dispatch({
    type: CLEAR_SUPPLY_FILTERS,
    payload: { type: 'all' }
  });

  dispatch({
    type: CLEAR_SUPPLY_CONTRACTS_FILTERS,
    payload: { type: 'all' }
  });

  return dispatch({
    type: CANCEL_NEW_REGISTRY,
    payload
  });
};

export const createNewRegistry = payload => ({
  type: CREATE_NEW_REGISTRY,
  payload: {
    request: {
      method: 'POST',
      url: 'registries',
      data: payload
    }
  }
});
