import {
  FETCH_SUPPLY_CONTRACT_BY_ID,
  FETCH_OFFERS_AVAILABILITY_CONDITIONS,
} from './SupplyContractInfo.actions';

const initialState = {
  isFetching: false,
  isError: false,
  error: null,
  data: {
    isSupplierProfileActive: false,
    isSupplyContractActive: false,
    offers: { records: [] }
  }
};


export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_SUPPLY_CONTRACT_BY_ID:
      return {
        data: { ...initialState.data },
        isFetching: true,
        isError: false,
        error: null
      };

    case `${FETCH_SUPPLY_CONTRACT_BY_ID}_SUCCESS`:
      return {
        isFetching: false,
        isError: false,
        error: null,
        data: {
          ...state.data,
          ...payload.data
        }
      };

    case `${FETCH_SUPPLY_CONTRACT_BY_ID}_FAIL`:
      return {
        data: { ...state.data },
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case FETCH_OFFERS_AVAILABILITY_CONDITIONS:
      return {
        data: { ...state.data },
        isFetching: false,
        isError: false,
        error: null
      };

    case `${FETCH_OFFERS_AVAILABILITY_CONDITIONS}_SUCCESS`:
      return {
        data: {
          ...state.data,
          ...payload.data,
        },
        isFetching: false,
        isError: false,
        error: null
      };

    case `${FETCH_OFFERS_AVAILABILITY_CONDITIONS}_FAIL`:
      return {
        data: { ...state.data },
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    default:
      return state;
  }
}

