import {
  NEW_FACTORING_REQUEST,
  UPDATE_NEW_FACTORING_REQUEST,
  CANCEL_NEW_FACTORING_REQUEST,
  CREATE_NEW_FACTORING_REQUEST,
  FETCH_ATTACHMENTS_LIST_NEW_FACTORING_REQUEST,
  SELECT_NEW_FACTORING_REQUEST_SUPPLY_CONTRACT_INFO,
} from './FactoringRequestNew.actions';

const initialState = {
  isFetching: false,
  isError: false,
  error: null,
  data: {
    supplyContract: null,
    supplyContractInfo: {
      isEndlessEndDate: false,
      factorIdList: [],
      paymentDelay: '',
      salesAmount: '',
      endingDate: null,
      documentFile: null,
      paymentCurrency: null
    },
    contactPersonInfo: {
      userFullName: '',
      positionName: '',
      phoneNumber: '',
      email: ''
    },
    attachments: null,
    attachmentsList: [],
    paymentCurrency: null,
  },
  activeTabId: 1,
  tabs: [
    {
      id: 1,
      active: true,
      disabled: false,
      prev: false,
      next: false
    },
    {
      id: 2,
      active: false,
      disabled: true,
      prev: true,
      next: false
    },
    {
      id: 3,
      active: false,
      disabled: true,
      prev: true,
      next: false
    },
    {
      id: 4,
      active: false,
      disabled: true,
      prev: true,
      next: false
    }
  ]
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case NEW_FACTORING_REQUEST:
      return {
        ...initialState,
        ...payload
      };

    case SELECT_NEW_FACTORING_REQUEST_SUPPLY_CONTRACT_INFO:
      return {
        ...initialState,
        ...payload,
        data: {
          ...state.data,
          ...payload.data
        }
      };

    case UPDATE_NEW_FACTORING_REQUEST:
      return {
        ...state,
        ...payload,
        data: {
          ...state.data,
          ...payload.data
        }
      };

    case CANCEL_NEW_FACTORING_REQUEST:
      return { ...initialState };

    case CREATE_NEW_FACTORING_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${CREATE_NEW_FACTORING_REQUEST}_SUCCESS`:
      return { ...initialState };

    case `${CREATE_NEW_FACTORING_REQUEST}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case FETCH_ATTACHMENTS_LIST_NEW_FACTORING_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${FETCH_ATTACHMENTS_LIST_NEW_FACTORING_REQUEST}_SUCCESS`:
      return {
        ...state,
        data: {
          ...state.data,
          attachmentsList: [...payload.data.records]
        },
        isFetching: false
      };

    case `${FETCH_ATTACHMENTS_LIST_NEW_FACTORING_REQUEST}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    default:
      return state;
  }
}
