import {
  FETCH_FACTORING_REQUEST_BY_ID,
  FETCH_OFFERS_BY_FACTORING_REQUEST_ID
} from './FactoringRequestInfo.actions';

const initialState = {
  isFetching: false,
  isError: false,
  data: {
    id: undefined,
    status: {
      value: '',
      displayName: '',
      displayType: ''
    },
    debtor: { companyName: '' },
    supplier: {
      inn: '',
      kpp: ''
    },
    factor: { companyName: '' },
    supplyContract: { id: 0 },
    supplyContractInfo: {
      paymentDelay: '',
      salesAmount: '',
      endingDate: 0,
      attachmentList: []
    },
    contactPersonInfo: {
      phoneNumber: '',
      email: '',
      userFullName: '',
      positionName: ''
    },
    attachmentList: [],
    offers: [],
    paymentCurrency: {}
  },
  error: null
};


export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_FACTORING_REQUEST_BY_ID:
      return {
        ...initialState,
        isFetching: true,
        isError: false
      };

    case `${FETCH_FACTORING_REQUEST_BY_ID}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        data: {
          ...state.data,
          ...payload.data,
          offers: [...state.data.offers]
        }
      };

    case `${FETCH_FACTORING_REQUEST_BY_ID}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case FETCH_OFFERS_BY_FACTORING_REQUEST_ID:
      return {
        ...state,
        data: {
          ...state.data,
          offers: [...initialState.data.offers]
        },
        isFetching: true,
        isError: false
      };

    case `${FETCH_OFFERS_BY_FACTORING_REQUEST_ID}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        data: {
          ...state.data,
          offers: [...payload.data.records]
        }
      };

    case `${FETCH_OFFERS_BY_FACTORING_REQUEST_ID}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    default:
      return state;
  }
}
