import { FETCH_CURRENCIES_LIST } from '../actions/currencies.actions';

const initialState = {
  isFetching: false,
  isError: false,
  error: null,
  data: { currencies: [], },
};

export default function currenciesReducer(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_CURRENCIES_LIST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${FETCH_CURRENCIES_LIST}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        data: {
          ...state.data,
          currencies: [...payload.data.records]
        }
      };

    case `${FETCH_CURRENCIES_LIST}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    default:
      return state;
  }
}
