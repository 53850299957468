import { chainMiddleware } from '../middlewares/chain.middleware';

import { fetchBadges } from '../actions/badges.actions';

import { CREATE_NEW_FACTORING_CONTRACT } from '../../app/FactoringContractNew/FactoringContractNew.actions';
import { CREATE_NEW_FACTORING_NOTIFICATION } from '../../app/FactoringNotificationNew/FactoringNotificationNew.actions';
import { CREATE_NEW_FACTORING_REQUEST } from '../../app/FactoringRequestNew/FactoringRequestNew.actions';
import { CREATE_NEW_REGISTRY } from '../../app/RegistryNew/RegistryNew.actions';
import { SIGN_FACTORING_CONTRACT } from '../../app/FactoringContractInfo/FactoringContractInfo.actions';
import { SIGN_FACTORING_NOTIFICATION } from '../../app/FactoringNotificationInfo/FactoringNotificationInfo.actions';
import { SIGN_REGISTRY } from '../../app/RegistryInfo/RegistryInfo.actions';
import { ACCEPT_OFFER } from '../../app/FactoringRequestInfo/FactoringRequestInfo.actions';

export const badgesChainMiddleware = chainMiddleware({
  target: fetchBadges,
  onSuccess: true,
  source: [
    CREATE_NEW_FACTORING_CONTRACT,
    CREATE_NEW_FACTORING_NOTIFICATION,
    CREATE_NEW_FACTORING_REQUEST,
    CREATE_NEW_REGISTRY,
    SIGN_FACTORING_CONTRACT,
    SIGN_FACTORING_NOTIFICATION,
    SIGN_REGISTRY,
    ACCEPT_OFFER,
  ],
});
