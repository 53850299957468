import React from 'react';
import L from 'react-loadable';
import { Loader } from '../shared/ui-components/Loader';

const Loading = () => (<Loader isFullSize />);

const Loadable = props => L({ loading: Loading, ...props });

export const LoadableRoutes = {
  Login: Loadable({ loader: () => import('./Login') }),
  Registration: Loadable({ loader: () => import('./Registration') }),
  RegistrationSuccess: Loadable({ loader: () => import('./Registration/RegistrationSuccess') }),
  RestorePassword: Loadable({ loader: () => import('./RestorePassword') }),
  Supply: Loadable({ loader: () => import('./Supply') }),
  SupplyInfo: Loadable({ loader: () => import('./SupplyInfo') }),
  SupplyUpload: Loadable({ loader: () => import('./SupplyUpload') }),
  SupplyContracts: Loadable({ loader: () => import('./SupplyContracts') }),
  SupplyContractInfo: Loadable({ loader: () => import('./SupplyContractInfo') }),
  FactoringNotifications: Loadable({ loader: () => import('./FactoringNotifications') }),
  FactoringNotificationInfo: Loadable({ loader: () => import('./FactoringNotificationInfo') }),
  FactoringNotificationNew: Loadable({ loader: () => import('./FactoringNotificationNew') }),
  FactoringContracts: Loadable({ loader: () => import('./FactoringContracts') }),
  FactoringContractNew: Loadable({ loader: () => import('./FactoringContractNew') }),
  FactoringContractInfo: Loadable({ loader: () => import('./FactoringContractInfo') }),
  Registries: Loadable({ loader: () => import('./Registries') }),
  RegistryInfo: Loadable({ loader: () => import('./RegistryInfo') }),
  RegistryNew: Loadable({ loader: () => import('./RegistryNew') }),
  OfferNew: Loadable({ loader: () => import('./OfferNew') }),
  Help: Loadable({ loader: () => import('./Help') }),
  Settings: Loadable({ loader: () => import('./Settings') }),
  FactoringRequests: Loadable({ loader: () => import('./FactoringRequests') }),
  FactoringRequestNew: Loadable({ loader: () => import('./FactoringRequestNew') }),
  FactoringRequestInfo: Loadable({ loader: () => import('./FactoringRequestInfo') })
};
