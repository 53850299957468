import userReducer from './user.reducer';
import authReducer from './auth.reducer';
import companiesReducer from './companies.reducer';
import notyReducer from './notifications.reducer';
import badgesReducer from './badges.reducer';
import registrationReducer from './registration.reducer';
import currenciesReducer from './currencies.reducer';
import supportNotificationsReducer from './support-notifications.reducer';

export default {
  user: userReducer,
  auth: authReducer,
  companies: companiesReducer,
  noty: notyReducer,
  badges: badgesReducer,
  registration: registrationReducer,
  currencies: currenciesReducer,
  supportNotifications: supportNotificationsReducer,
};
