import { cloneDeep } from 'lodash';

/**
 * Retry config example:
 * {
 *   retries: number (default: 3) - Количество попыток
 *   retryDelay: number|(try: number) => number (default: 0) - Задержка между попытками
 *   throwError: boolean (default: true) - выбрасывать ошибку на текущей итерации, если кол-во попыток не закончилось
 *   onErrorCallback?: (dispatch) => void - колбек для вызова экшенов в случае ошибки
 * }
 */

const retryInterceptor = {
  success: (_, res) => Promise.resolve(res),
  error: ({ dispatch }, error) => {
    const { config: { retry = null, reduxSourceAction } = {} } = error;

    if (retry === null) return Promise.reject(error);

    if (!retry.hasOwnProperty('meta')) retry.meta = { tries: 0 };

    const { retries = 3, retryDelay = 0, throwError = true, onErrorCallback } = retry;

    if (retry.meta.tries >= retries) {
      if (onErrorCallback instanceof Function) onErrorCallback(dispatch);
      return Promise.reject(error);
    }

    retry.meta.tries++;

    const runPreviousAction = () => {
      const action = cloneDeep(reduxSourceAction);
      action.payload.request.retry = retry;
      dispatch(action);
    };

    if (retryDelay instanceof Function) setTimeout(runPreviousAction, retryDelay(retry.meta.tries));
    else if (retryDelay > 0) setTimeout(runPreviousAction, retryDelay);
    else runPreviousAction();

    return throwError ? Promise.reject(error) : null;
  },
};

export default retryInterceptor;
