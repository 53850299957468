const HANDLE_ERROR = 'HANDLE_ERROR';

export const handleError = payload => ({
  type: HANDLE_ERROR,
  payload: {
    request: {
      method: 'POST',
      url: '/send_error',
      data: payload
    }
  }
});
