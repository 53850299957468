export const FETCH_SUPPLY_BY_ID = 'FETCH_SUPPLY_BY_ID';

export const fetchSupplyById = documentId => ({
  type: FETCH_SUPPLY_BY_ID,
  payload: {
    request: {
      method: 'GET',
      url: `supply/${documentId}`
    }
  }
});
