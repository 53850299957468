export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const onLogin = payload => ({
  type: LOGIN,
  payload: {
    request: {
      method: 'POST',
      url: 'auth/login',
      data: payload
    }
  }
});

export const onLogout = () => ({
  type: LOGOUT,
  payload: {
    request: {
      method: 'POST',
      url: 'auth/logout'
    }
  }
});
