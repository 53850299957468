export const NEW_FACTORING_REQUEST = 'NEW_FACTORING_REQUEST';
export const UPDATE_NEW_FACTORING_REQUEST = 'UPDATE_NEW_FACTORING_REQUEST';
export const SELECT_NEW_FACTORING_REQUEST_SUPPLY_CONTRACT_INFO = 'SELECT_NEW_FACTORING_REQUEST_SUPPLY_CONTRACT_INFO';
export const CANCEL_NEW_FACTORING_REQUEST = 'CANCEL_NEW_FACTORING_REQUEST';
export const CREATE_NEW_FACTORING_REQUEST = 'CREATE_NEW_FACTORING_REQUEST';
export const FETCH_ATTACHMENTS_LIST_NEW_FACTORING_REQUEST = 'FETCH_ATTACHMENTS_LIST_NEW_FACTORING_REQUEST';

export const initNewFactoringRequest = payload => dispatch => dispatch({
  type: NEW_FACTORING_REQUEST,
  payload
});

export const updateNewFactoringRequest = payload => ({
  type: UPDATE_NEW_FACTORING_REQUEST,
  payload
});

export const selectNewFactoringRequestSupplyContract = payload => ({
  type: SELECT_NEW_FACTORING_REQUEST_SUPPLY_CONTRACT_INFO,
  payload
});

export const cancelNewFactoringRequest = payload => dispatch => dispatch({
  type: CANCEL_NEW_FACTORING_REQUEST,
  payload
});

export const createNewFactoringRequest = payload => ({
  type: CREATE_NEW_FACTORING_REQUEST,
  payload: {
    request: {
      method: 'POST',
      url: 'factoring-requests',
      data: payload
    }
  }
});

export const fetchAttachmentsListNewFactoringRequest = (params = {}) => ({
  type: FETCH_ATTACHMENTS_LIST_NEW_FACTORING_REQUEST,
  payload: {
    request: {
      method: 'GET',
      url: 'dictionary/required-attachments',
      params
    }
  }
});
