import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  FETCH_FACTORING_CONTRACTS,
  LOAD_MORE_FACTORING_CONTRACTS,
  UPDATE_FACTORING_CONTRACTS_FILTERS,
  CLEAR_FACTORING_CONTRACTS_FILTERS, SET_FACTORING_CONTRACTS_SORTING
} from './FactoringContracts.actions';
import { LOGOUT } from '../../shared/actions/auth.actions';

const initialState = {
  isFetching: true,
  isError: false,
  error: null,
  data: {
    records: [],
    isLast: true,
    totalPages: 0,
    totalElements: 0,
  },
  filters: {
    statusList: [],
    factorIdList: [],
    supplierIdList: [],
    documentNumber: '',
    documentDate: {
      start: null,
      end: null
    },
    endingDate: {
      start: null,
      end: null
    },
  },
  sorting: {
    direction: '',
    by: '',
  },
};

/** @type {import('redux-persist').PersistConfig} */
const persistConfig = {
  storage,
  key: 'factoring-contracts-list-cfg',
  whitelist: ['filters', 'sorting'],
};

function FactoringContractsListReducer(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_FACTORING_CONTRACTS:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${FETCH_FACTORING_CONTRACTS}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        data: { ...state.data, ...payload.data },
      };

    case `${FETCH_FACTORING_CONTRACTS}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case LOAD_MORE_FACTORING_CONTRACTS:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${LOAD_MORE_FACTORING_CONTRACTS}_SUCCESS`:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.data,
          records: [
            ...state.data.records,
            ...payload.data.records
          ]
        },
        isFetching: false,
        isError: false,
        error: null
      };

    case `${LOAD_MORE_FACTORING_CONTRACTS}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case UPDATE_FACTORING_CONTRACTS_FILTERS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        filters: {
          ...state.filters,
          ...payload
        },
      };

    case CLEAR_FACTORING_CONTRACTS_FILTERS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        filters: payload.type === 'all'
          ? { ...initialState.filters }
          : {
            ...state.filters,
            ...{ [payload.type]: initialState.filters[payload.type] }
          }
      };

    case SET_FACTORING_CONTRACTS_SORTING:
      return {
        ...state,
        sorting: payload,
      };

    case LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
}

export default persistReducer(persistConfig, FactoringContractsListReducer);
