import {
  FETCH_FACTORING_CONTRACT_BY_ID,
  FETCH_SUPPLY_CONTRACTS_BY_FACTOR_CONTRACT,
  SIGN_FACTORING_CONTRACT,
  FETCH_FACTORING_CONTRACT_TIMELINE_BY_ID,
  FINISH_FACTORING_CONTRACT,
  COMMENT_FACTORING_CONTRACT,
  FETCH_FACTORING_CONTRACT_COMMENTS
} from './FactoringContractInfo.actions';

const initialState = {
  isFetching: false,
  isError: false,
  data: {},
  timeline: {},
  supplyContractsList: {},
  comments: { records: [] },
  error: null
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_FACTORING_CONTRACT_BY_ID:
      return {
        ...initialState,
        isFetching: true,
        isError: false
      };

    case `${FETCH_FACTORING_CONTRACT_BY_ID}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        data: {
          ...state.data,
          ...payload.data
        }
      };

    case `${FETCH_FACTORING_CONTRACT_BY_ID}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case FETCH_SUPPLY_CONTRACTS_BY_FACTOR_CONTRACT:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${FETCH_SUPPLY_CONTRACTS_BY_FACTOR_CONTRACT}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        supplyContractsList: { ...payload.data }
      };

    case `${FETCH_SUPPLY_CONTRACTS_BY_FACTOR_CONTRACT}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case FINISH_FACTORING_CONTRACT:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${FINISH_FACTORING_CONTRACT}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        data: {
          ...state.data,
          ...payload.data
        }
      };

    case `${FINISH_FACTORING_CONTRACT}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case COMMENT_FACTORING_CONTRACT:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${COMMENT_FACTORING_CONTRACT}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        comments: { records: [payload.data] }
      };

    case `${COMMENT_FACTORING_CONTRACT}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case FETCH_FACTORING_CONTRACT_COMMENTS:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${FETCH_FACTORING_CONTRACT_COMMENTS}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        comments: { ...payload.data }
      };

    case `${FETCH_FACTORING_CONTRACT_COMMENTS}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case SIGN_FACTORING_CONTRACT:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${SIGN_FACTORING_CONTRACT}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        data: payload.data.error ? { ...state.data } : { ...payload.data }
      };

    case `${SIGN_FACTORING_CONTRACT}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    case FETCH_FACTORING_CONTRACT_TIMELINE_BY_ID:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${FETCH_FACTORING_CONTRACT_TIMELINE_BY_ID}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        timeline: { ...payload.data }
      };

    case `${FETCH_FACTORING_CONTRACT_TIMELINE_BY_ID}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload }
      };

    default:
      return state;
  }
}
