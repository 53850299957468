import { generateID } from '../utils/generateID';

export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

const DEFAULT_DELAY = 10000;

export const createNoty = data => dispatch => {
  const payload = { id: generateID('id', 'noty'), ...data };

  dispatch({
    type: CREATE_NOTIFICATION,
    payload
  });

  setTimeout(() => dispatch({ type: REMOVE_NOTIFICATION, payload }), payload.delay || DEFAULT_DELAY);
};

export const removeNoty = payload => dispatch => dispatch({
  type: REMOVE_NOTIFICATION,
  payload
});
