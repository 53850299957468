import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Notification } from '../../ui-components/Notification';

import { removeNoty } from '../../actions/notifications.actions';

import styles from './Notifications.module.scss';

export class Notifications extends Component {
  static propTypes = {
    notifications: PropTypes.array,
    onRemoveNoty: PropTypes.func
  };

  static defaultProps = {
    notifications: [],
    onRemoveNoty: (f) => f
  };

  render() {
    const { notifications, onRemoveNoty } = this.props;
    return (
      <ReactCSSTransitionGroup
        className={styles.root}
        component="div"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
        transitionName={{
          enter: `${styles.enter}`,
          enterActive: `${styles['enter-active']}`,
          leave: `${styles.leave}`,
          leaveActive: `${styles['leave-active']}`
        }}
      >
        {notifications.map((notification) => (<Notification key={notification.id} notification={notification} onRemove={() => onRemoveNoty(notification)}/>))}
      </ReactCSSTransitionGroup>
    );
  }
}

const mapStateToProps = (state) => ({ notifications: state.noty });

const mapDispatchToProps = (dispatch, actions = {}) => {
  actions.onRemoveNoty = removeNoty;
  return bindActionCreators(actions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
