import { applyMiddleware, combineReducers, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { composeWithDevTools } from 'redux-devtools-extension';
import { multiClientMiddleware } from 'redux-axios-middleware';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import FactoringNotifications from '../../app/FactoringNotifications/FactoringNotifications.reducer';
import FactoringNotificationInfo from '../../app/FactoringNotificationInfo/FactoringNotificationInfo.reducer';
import FactoringNotificationNew from '../../app/FactoringNotificationNew/FactoringNotificationNew.reducer';

import FactoringContracts from '../../app/FactoringContracts/FactoringContracts.reducer';
import FactoringContractNew from '../../app/FactoringContractNew/FactoringContractNew.reducer';
import FactoringContractInfo from '../../app/FactoringContractInfo/FactoringContractInfo.reducer';

import Supply from '../../app/Supply/Supply.reducer';
import SupplyInfo from '../../app/SupplyInfo/SupplyInfo.reducer';
import SupplyUpload from '../../app/SupplyUpload/SupplyUpload.reducer';
import SupplyContracts from '../../app/SupplyContracts/SupplyContracts.reducer';
import SupplyContractInfo from '../../app/SupplyContractInfo/SupplyContractInfo.reducer';

import Registries from '../../app/Registries/Registries.reducer';
import RegistryInfo from '../../app/RegistryInfo/RegistryInfo.reducer';
import RegistryNew from '../../app/RegistryNew/RegistryNew.reducer';

import FactoringRequests from '../../app/FactoringRequests/FactoringRequests.reducer';
import FactoringRequestNew from '../../app/FactoringRequestNew/FactoringRequestNew.reducer';
import FactoringRequestInfo from '../../app/FactoringRequestInfo/FactoringRequestInfo.reducer';

import OfferNew from '../../app/OfferNew/OfferNew.reducer';

import sharedReducers from '../reducers';

import expireSessionInterceptor from '../interceptors/expireSession.interceptor';
import retryInterceptor from '../interceptors/retry.interceptor';

import { clients } from '../clients';

import { badgesChainMiddleware } from './badges.chain-middleware';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
  stateReconciler: autoMergeLevel2,
};

const reducers = {
  FactoringContracts,
  FactoringContractInfo,
  FactoringContractNew,
  FactoringNotifications,
  FactoringNotificationInfo,
  FactoringNotificationNew,
  SupplyContracts,
  SupplyContractInfo,
  Supply,
  SupplyUpload,
  SupplyInfo,
  Registries,
  RegistryInfo,
  RegistryNew,
  FactoringRequests,
  FactoringRequestNew,
  FactoringRequestInfo,
  OfferNew,
  ...sharedReducers,
};

const axiosMiddlewareConfig = {
  returnRejectedPromiseOnError: true,
  interceptors: {
    request: [],
    response: [expireSessionInterceptor, retryInterceptor],
  },
};

const middlewares = [thunk, multiClientMiddleware(clients, axiosMiddlewareConfig), badgesChainMiddleware];

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });

const store = createStore(persistReducer(persistConfig, combineReducers(reducers)), composeEnhancers(applyMiddleware(...middlewares)));

const persistor = persistStore(store);

export { store, persistor, reducers, middlewares };
