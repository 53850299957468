import { FETCH_SUPPORT_NOTIFICATIONS } from '../actions/support-notifications.actions';

const initialState = {
  isFetching: false,
  isError: false,
  error: null,
  notificationList: [],
};

export default function supportNotificationsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case `${FETCH_SUPPORT_NOTIFICATIONS}`:
      return {
        ...state,
        isFetching: true,
        isError: false,
        error: null
      };

    case `${FETCH_SUPPORT_NOTIFICATIONS}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        error: null,
        notificationList: payload?.data?.records ?? [],
      };

    case `${FETCH_SUPPORT_NOTIFICATIONS}_FAIL`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: { ...payload },
        notificationList: [{
          id: -1,
          type: 'ERROR',
          message: 'Уважаемые коллеги! На платформе Мегафон Факторинг проводятся технические работы. Попробуйте позже.',
        }],
      };

    default:
      return state;
  }
}
