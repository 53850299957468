export const NEW_OFFER = 'NEW_OFFER';
export const CANCEL_SEND_NEW_OFFER = 'CANCEL_SEND_NEW_OFFER';
export const SEND_NEW_OFFER = 'SEND_NEW_OFFER';
export const FETCH_FACTORING_REQUEST_FOR_OFFER_BY_ID = 'FETCH_FACTORING_REQUEST_FOR_OFFER_BY_ID';


export const newOffer = payload => ({
  type: NEW_OFFER,
  payload
});

export const cancelSendNewOffer = payload => ({
  type: CANCEL_SEND_NEW_OFFER,
  payload
});

export const sendNewOffer = data => ({
  type: SEND_NEW_OFFER,
  payload: {
    request: {
      method: 'POST',
      url: 'offers',
      data
    }
  }
});

export const fetchFactoringRequestForOfferById = documentId => ({
  type: FETCH_FACTORING_REQUEST_FOR_OFFER_BY_ID,
  payload: {
    request: {
      method: 'GET',
      url: `factoring-requests/${documentId}`
    }
  }
});
